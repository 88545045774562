@import "src/styles/mixins";

.taskbar {
  display: flex;
  width: 240px;

  &-link {
    text-decoration: none;

    &.disabled-link {
      pointer-events: none;
      .taskbar-item {
        &__reporting {
          path:nth-child(0), path:nth-child(6) {
            stroke: #2d5589;
          }

          path:nth-child(2), path:nth-child(4) {
            stroke: #2d5589;
            fill: #2d5589;
          }
        }
        svg {
          color: #2d5589;
        }
      }
    }
  }

  &-link.active {
    .taskbar-item {
      background: var(--accent-purple);

      &__grid {
        rect:nth-child(1),
        rect:nth-child(2),
        rect:nth-child(3),
        rect:nth-child(4) {
          fill: var(--bg-primary-blue-light);
        }

        rect:nth-child(5),
        rect:nth-child(6),
        rect:nth-child(7),
        rect:nth-child(8) {
          fill: var(--accent-purple);
        }
      }

      &__map {
        color: var(--bg-primary-blue-light);
      }

      &__reporting {
        path:nth-child(0) {
          stroke: var(--bg-primary-blue-light);
        }

        path:nth-child(2), path:nth-child(4) {
          stroke: var(--bg-primary-blue-light);
          fill: var(--bg-primary-blue-light);
        }

        path:nth-child(6) {
          stroke: var(--bg-primary-blue-light);
          stroke-width: 3;
        }
      }
    }
  }

  &-item {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid var(--border-color);
    @include flex-justify-align-center;
    margin-right: 16px;
    cursor: pointer;
    color: var(--accent-purple);

    &:hover {
      border: 1px solid var(--accent-purple);
    }

    &.disabled-link {
      pointer-events: none;
      color: #2d5589;
    }

    &__event {
      width: 18px;
      height: 18px;
      @include event-icon(var(--accent-purple), var(--bg-primary-blue-light));
    }
  }

  &-item.active {
    background: var(--accent-purple);
  }

  &-item.feed.active {
    path:nth-child(2) {
      fill: var(--accent-purple);
    }

    path:nth-child(1) {
      fill: var(--bg-primary-blue-light);
    }
  }

  .charts, .settings {
    order: 1;
  }
}