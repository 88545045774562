@keyframes dot-typing {
  0% {
    box-shadow: 9978px 0 0 0, 9984px 0 0 0, 9990px 0 0 0;
  }
  16% {
    box-shadow: 9978px -5px 0 0, 9984px 0 0 0, 9990px 0 0 0;
  }
  33% {
    box-shadow: 9978px 0 0 0, 9984px 0 0 0, 9990px 0 0 0;
  }
  50% {
    box-shadow: 9978px 0 0 0, 9984px -5px 0 0, 9990px 0 0 0;
  }
  66% {
    box-shadow: 9978px 0 0 0, 9984px 0 0 0, 9990px 0 0 0;
  }
  83% {
    box-shadow: 9978px 0 0 0, 9984px 0 0 0, 9990px -5px 0 0;
  }
  100% {
    box-shadow: 9978px 0 0 0, 9984px 0 0 0, 9990px 0 0 0;
  }
}

@keyframes sidebar-item-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 500%;
    height: 500%;
  }
}