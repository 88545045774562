@import "src/styles/mixins";

.client-panel__filter {
  width: 48px;
  height: 48px;
  background: var(--bg-primary-blue-light);
  border-radius: 50%;
  @include flex-justify-align-center;
  padding: 13.5px 8px;
  margin-bottom: 16px;
  cursor: pointer;
  border: 2px solid transparent;

  &-text {
    @include text-400-18-center;
    @include flex-justify-align-center;
    color: var(--accent-turquoise);
  }

  &:hover {
    border: 2px solid var(--accent-turquoise);
  }
}

.all {
  background: var(--accent-turquoise);

  .client-panel__filter-text {
    color: var(--bg-primary-blue-light);
  }
}