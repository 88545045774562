@import "src/styles/mixins";

.sidebar {
  width: 72px;
  height: 100%;
  background: var(--bg-primary-blue-dark);
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.15);
  @include flex-dir-col-align-start;
  justify-content: space-between;
  border-right: 1px solid var(--content-gray-blue-100);
  &-top {
    padding: 16px 12px;

    &__logo {
      img {
        width: 48px;
        height: 48px;
      }
      &-line {
        width: 48px;
        height: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        background: var(--bg-primary-blue-light);
        border-radius: 30px;
      }
    }
  }

  &-bottom {
    width: 100%;
    &__line {
      width: 100%;
      height: 1px;
      background: var(--content-gray-blue-100);
    }

    &__nav {
      @include flex-dir-col-align-center;
      img  {
        width: 26px;
        height: 26px;
        margin-top: 16px;
        margin-bottom: 16px;
        cursor: pointer;
        &:last-child {
          margin-top: 0;
        }
      }
    }
  }
}