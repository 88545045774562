@import "src/styles/mixins";

.header {
  width: 100%;
  height: 48px;
  background: var(--bg-primary-blue);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid var(--content-gray-blue-100);
  @include flex-align-center-justify-between;

  &-right {
    max-width: 480px;
    height: 48px;
    padding: 12.5px 32px;

    &__name {
      @include text-700-20-23;
      color: var(--accent-turquoise);
    }
  }

  &-left {
    display: flex;
  }
}