@import "src/styles/mixins";

.toast {
  min-width: 221px;
  &-icon {
    padding: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    @include flex-justify-align-center;
    border: 2px solid var(--bg-primary-blue-light);
  }
}