@import "src/styles/mixins";

.client-panel {
  &__add {
    width: 48px;
    height: 48px;
    background: var(--bg-primary-blue-light);
    border-radius: 50%;
    @include flex-justify-align-center;
    padding: 13.5px 8px;
    margin-bottom: 16px;
    cursor: pointer;
    border: 2px solid transparent;
    margin-top: 16px;

    &-text {
      @include text-400-18-center;
      @include flex-justify-align-center;
      color: var(--accent-turquoise);
    }

    &:hover {
      border: 2px solid var(--accent-turquoise);
    }
  }

  &__main {
    max-height: 760px;
    overflow-y: auto;
    z-index: 2;
    position: relative;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .full {
    width: 158px;
  }
}
@media screen and (max-height: 1042px) {
  .client-panel {
    &__main {
      max-height: 695px;
    }
  }
}

@media screen and (max-height: 977px) {
  .client-panel {
    &__main {
      max-height: 630px;
    }
  }
}

@media screen and (max-height: 912px) {
  .client-panel {
    &__main {
      max-height: 565px;
    }
  }
}

@media screen and (max-height: 847px) {
  .client-panel {
    &__main {
      max-height: 500px;
    }
  }
}

@media screen and (max-height: 782px) {
  .client-panel {
    &__main {
      max-height: 440px;
    }
  }
}

@media screen and (max-height: 722px) {
  .client-panel {
    &__main {
      max-height: 372px;
    }
  }
}

@media screen and (max-height: 654px) {
  .client-panel {
    &__main {
      max-height: 310px;
    }
  }
}