@import "src/styles/mixins";

.search {
  position: relative;
  &-inner {
    width: 298px;
    position: relative;
    margin-right: 16px;
    &__field {
      width: 100%;
      height: 32px;
      border: 1px solid rgba(147, 165, 182, 0.25);
      border-radius: 4px;
      background: var(--bg-primary-blue);
      padding: 6px 10px 6px 41px;
      @include text-400-16-19;
      color: var(--content-white);
      &:focus {
        outline: 1px solid var(--accent-purple-dark);
      }
    }


    &__icon {
      position: absolute;
      left: 5px;
      height: 32px;
      @include flex-align-center;
      margin-left: 10px;
    }
  }

  &-response {
    width: 298px;
    height: 162px;
    border-radius: 4px;
    background: rgba(10, 37, 64, 0.9);
    border: 1px solid rgba(115, 127, 139, 0.2);
    position: absolute;
    top: 40px;
    padding: 10px;
    overflow-y: auto;
    z-index: 1;

    &__result {
      @include flex-dir-col;
      text-decoration: none;
      color: var(--content-gray-dark);
      margin-bottom: 6px;
      padding: 5px 10px;
      border-radius: 4px;
      @include text-400-16-19;
      &:hover {
        background: rgba(12, 46, 78, 0.9);
        color: var(--content-white);
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}