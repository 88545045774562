@import "src/styles/mixins";

.footer {
  width: 628px;
  @include flex-align-center;

  &-list {
    list-style: none;
    display: flex;
    &__item {
      &::after {
        content: '|';
        margin-left: 8px;
        margin-right: 8px;
        color: var(--content-gray-blue-50);
      }
      &-link {
       @include text-400-12-14-gray-blue-50;
        text-decoration: none;
      }
    }
  }
  &-copyright {
    @include text-400-12-14-gray-blue-50;
  }
}