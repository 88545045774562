:root {
  --bg-primary-blue-dark: #06182C;
  --bg-primary-blue: #0A2540;
  --bg-primary-blue-light: #0C2E4E;
  --bg-dark-blue: #16385E;
  --accent-orange: #FFA956;
  --accent-red: #D53235;
  --accent-green: #6AFF8F;
  --accent-turquoise: #00D4FF;
  --accent-torquoise-light: #8AEBFF;
  --accent-purple-dark: #7A73FF;
  --accent-purple: #8095FF;
  --accent-purple-semi-dark: #818ED1;
  --accent-yellow: #FFD96A;
  --content-white: #F8F9FA;
  --content-gray-dark: #737F8B;
  --content-gray: #C8D0D9;
  --content-gray-blue-100: #203A53;
  --content-gray-blue-70: #55718D;
  --content-gray-blue-50: #93A5B6;
  --content-gray-blue-20: #C7D5E2;
  --border-color: #2B455B;
  --primary: #2F445D;
}