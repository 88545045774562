@import "src/styles/mixins";

.home {
  width: 100%;
  height: 100%;
  display: flex;
}

.content {
  @include flex-dir-col;
  width: 100%;

  .main {
    background: #01304c;
    width: 100%;
    height: calc(100% - 48px);
    @include flex-dir-col-align-center;
  }
}