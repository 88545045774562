@mixin flex-justify-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-dir-col-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-dir-col-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin flex-dir-col-justify-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-align-center-justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-dir-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-justify-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-align-center-justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-justify-end {
  display: flex;
  justify-content: flex-end;
}


/* main styles buttons */
@mixin btn {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  padding: 4px 8px;
  @include flex-justify-align-center;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid transparent;
}

@mixin btn-outline {
  min-width: 118px;
  height: 41px;
  border-radius: 6px;
  @include flex-justify-align-center;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  border: 2px solid transparent;
  padding: 10px 30px;
  text-decoration: none;
}

@mixin btn-link {
  width: 96px;
  height: 41px;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background: transparent;
  @include text-500-18-21;
  @include flex-justify-align-center;
}
/* main styles buttons END */

/* regular buttons */
@mixin btn-secondary {
  @include btn;
  color: rgba(199, 213, 226, 0.65);
  background: rgba(147, 165, 182, 0.15);
  &:hover {
    border-color: var(--content-gray-blue-20);
    color: var(--content-gray-blue-20);
    background: rgba(147, 165, 182, 0.3);
  }
  &.active {
    border-color: var(--content-gray-blue-20);
    color: var(--content-gray-blue-20);
    background: rgba(147, 165, 182, 0.3);
  }
}

@mixin btn-info {
  @include btn;
  color: rgba(0, 212, 255, 0.5);
  background: rgba(0, 212, 255, 0.15);
  &:hover {
    border-color: var(--accent-turquoise);
    color: var(--accent-turquoise);
  }
  &.active {
    background: var(--accent-turquoise);
    color: var(--bg-primary-blue-light);
  }
}

@mixin btn-default {
  @include btn;
  width: 25px;
  color: rgba(128, 149, 255, 0.5);
  background: transparent;
  &:hover, &.active {
    color: var(--accent-purple);
    background: rgba(128, 149, 255, 0.15);
  }
}

@mixin btn-primary {
  @include btn;
  color: rgba(128, 149, 255, 0.7);
  background: rgba(128, 149, 255, 0.15);
  &:hover, &.active {
    background: var(--accent-purple);
    color: var(--bg-primary-blue-light);
  }
}

@mixin btn-danger {
  @include btn;
  color: var(--accent-red);
  background: rgba(213, 50, 53, 0.3);
  &:hover {
    border-color: var(--accent-red);
  }
  &.active {
    background: var(--accent-red);
    color: var(--content-white);
  }
}

@mixin btn-success {
  @include btn;
  width: 67px;
  background: rgba(106, 255, 143, 0.2);
  color: var(--accent-green);
  &:hover,&.active {
    background: var(--accent-green);
    color: var(--bg-primary-blue-light);
  }
  &:disabled {
    background: transparent;
    color: var(--content-gray-blue-50);
    border-color: var(--content-gray-blue-50);

    &:hover {
      background: transparent;
      color: var(--content-gray-blue-50);
    }
  }
}

@mixin btn-warning {
  @include btn;
  width: 79px;
  color: rgba(255, 217, 106, 0.75);
  background: rgba(255, 217, 106, 0.15);
  &:hover, &.active {
    color: var(--bg-primary-blue-light);
    background: var(--accent-yellow);
  }
}
/* regular buttons END */

/* outline buttons */
@mixin btn-info-outline {
  @include btn-outline;
  color: var(--accent-turquoise);
  //background: var(--bg-primary-blue-light);
  background: transparent;
  border: 2px solid var(--accent-turquoise);
  &:hover {
    background: var(--accent-turquoise);
    color: var(--bg-primary-blue-light);
  }
  &:disabled {
    color: var(--content-gray-dark);
    border-color: var(--content-gray-dark);

    &:hover {
      background: transparent;
      color: var(--content-gray-dark);
    }
  }
}

@mixin btn-primary-outline {
  @include btn-outline;
  color: var(--accent-purple-dark);
  background: transparent;
  border: 2px solid var(--accent-purple-dark);
  &:hover {
    color: var(--content-white);
    background: var(--accent-purple-dark);
  }
}

@mixin btn-danger-outline {
  @include btn-outline;
  color: var(--accent-red);
  background: transparent;
  border: 2px solid var(--accent-red);
  &:hover {
    color: var(--content-white);
    background: var(--accent-red);
  }
}
/* outline buttons END */


/* link buttons */
@mixin btn-info-link {
  @include btn-link;
  color: var(--content-gray-blue-50);
  &:hover {
    color: var(--accent-turquoise);
  }
}

@mixin btn-primary-link {
  @include btn-link;
  color: var(--content-gray-blue-70);
  &:hover {
    color: var(--accent-purple-dark);
  }
}
/* link buttons END */

@mixin event-icon($bg, $text) {
  path:nth-child(1) {
    fill: $bg;
  }
  path:nth-child(2) {
    fill: $text;
  }
}

@mixin text-700-24-24 {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}

@mixin text-700-20-23 {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

@mixin text-700-16-19 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

@mixin text-700-12-14 {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

@mixin text-700-11-13 {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
}

@mixin text-500-23-27 {
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
}

@mixin text-500-18-21 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

@mixin text-500-16-19 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

@mixin text-400-22-26-white {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: var(--content-white);
}

@mixin text-400-16-24 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-400-14-16 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

@mixin text-400-12-14 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

@mixin text-400-12-14-gray-blue-50 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--content-gray-blue-50);
}

@mixin text-400-24-28 {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

@mixin text-400-20-23 {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

@mixin text-400-16-19 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

@mixin text-400-14-18 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@mixin text-400-18-center {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

@mixin camera-icon {
  width: 24px;
  height: 24px;
  background: rgba(128, 149, 255, 0.15);
  border-radius: 4px;
  padding: 2px;
  margin-right: 12px;
}