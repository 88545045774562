@import "src/styles/mixins";

.client-panel__main-item {
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  &__inner {
    width: 48px;
    height: 48px;
    background: var(--bg-primary-blue-light);
    border-radius: 50%;
    @include flex-justify-align-center;
    padding: 13.5px 8px;
    cursor: pointer;
    border: 2px solid transparent;
    position: absolute;
    z-index: 1;
    transition: 0.2s ease-in-out;

    &-text {
      color: var(--content-gray-blue-70);
      @include text-400-18-center;
      @include flex-justify-align-center;
    }

    &-icon {
      font-weight: 300;
      font-size: 36px;
      text-align: center;
    }

    &:hover {
      position: absolute;
      width: 154px;
      border-radius: 30px;
      border: 2px solid var(--accent-turquoise);
      transition: 0.2s ease-in-out;
    }

    &:hover &-text {
      color: var(--accent-turquoise);
      animation: sidebar-item-fade 1s linear;
    }
  }

  &:last-child {
    margin-bottom: unset;
  }
}

.selected {
  border: 2px solid var(--accent-turquoise);

  .client-panel__main-item__inner-text {
    color: var(--accent-turquoise);
  }
}

.no-signal {
  background: var(--accent-red);
  .client-panel__main-item__inner-text {
    color: var(--content-white);
  }

  &:hover {
   border-color: var(--accent-red);
    .client-panel__main-item__inner-text {
      color: var(--content-white);
    }
  }
}