@import "src/styles/mixins";

.login {
  width: 100%;
  height: 100%;
  background: var(--bg-primary-blue-dark);

  &-wrapper {
    width: 100%;
    height: calc(100% - 64px);
    @include flex-justify-align-center;

    .login-content {
      width: 456px;
      min-height: 236px;
      img {
        width: 100%;
        height: 100%;
      }
      @include flex-dir-col-align-center;

      &__heading {
        @include text-400-22-26-white;
        margin-top: 24px;
        margin-bottom: 17px;
      }

      .login-form {
        position: relative;
        @include flex-dir-col;
        margin-bottom: 8px;

        &__field {
          width: 328px;
          height: 42px;
          background: rgba(147, 165, 182, 0.15);
          border: 1px solid rgba(147, 165, 182, 0.15);
          border-radius: 4px;
          @include text-400-16-19;
          text-indent: 15px;
          color: var(--content-white);

          &:focus {
            outline: none;
            border: 1px solid var(--accent-turquoise);
          }
        }

        &__icon {
          width: 26px;
          height: 26px;
          border: 1px solid var(--content-gray-blue-50);
          background: #182e3f;
          border-radius: 50%;
          position: absolute;
          right: 12px;
          top: 8px;
          cursor: pointer;
          @include flex-justify-align-center;
        }
      }

      .password {
        opacity: 0;
        height: 42px;
      }

      .opacity {
        opacity: 1;
      }

      .login-signed, .login-forgot {
        @include flex-justify-center;
      }

      .login-signed {
        margin-top: 18px;
      }

      .login-forgot {
        margin-top: 39px;
        &__link {
          @include text-400-14-16;
          text-decoration: none;
          color: var(--accent-turquoise);
        }
      }
    }
  }

  &-errors {
    height: 14px;
  }

  &-loader {
    margin-top: 24px;
    @include text-400-22-26-white;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 26px;
    width: 105px;
    .dot-typing {
      position: relative;
      left: -9999px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: var(--content-white);
      box-shadow: 9978px 0 0 0, 9984px 0 0 0, 9990px 0 0 0;
      animation: dot-typing 1.5s infinite linear;
    }
  }

  &-footer {
    @include flex-justify-center;
    width: 100%;
  }
}

