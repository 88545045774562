html {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

* {
  padding: 0;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#root {
  width: 100%;
  height: 100%;
}

.error {
  @include flex-justify-end;
  @include text-700-16-19;
  color: var(--accent-red);
}

.valid--error {
  border: 1px solid var(--accent-red);
}

.dash {
  width: 8px;
  height: 2px;
  background: var(--content-gray-blue-70);
  border-radius: 50px;
  margin-right: 8px;
  margin-left: 8px;
}

.site {
  padding: 4px 8px;
  width: 61px;
  height: 24px;
  background: rgba(6, 24, 44, 0.4);
  border: 1px solid rgba(115, 127, 139, 0.4);
  border-radius: 4px;
  @include text-700-12-14;
  color: var(--content-white);
}

.camera-icon {
  path:nth-child(1) {
    fill: var(--accent-purple);
  }

  path:nth-child(2) {
    fill: var(--bg-primary-blue);
  }
}

/* events icons */

.event-primary-icon {
  path:nth-child(1) {
    fill: var(--accent-purple);
  }

  path:nth-child(2) {
    fill: var(--bg-primary-blue);
  }
}

/* events icons END */

.field {
  padding: 10px;
  width: 100%;
  height: 38px;
  background: rgba(147, 165, 182, 0.1);
  border-radius: 4px;
  color: var(--content-gray-blue-50);
  border: 1px solid transparent;
  outline: none;

  &::placeholder {
    color: var(--content-gray-blue-50);
  }

  &:focus {
    border: 1px solid var(--accent-purple);
  }
}


.investigate {
  background: var(--accent-orange);
  color: var(--bg-primary-blue-light);
}

.threat {
  background: var(--accent-red);
  color: var(--content-white);
}

.safe, .resolved {
  background: var(--accent-green);
  color: var(--bg-primary-blue-light);
}

.other {
  background: var(--accent-purple);
  color: var(--bg-primary-blue-light);
}

.btn-primary {
  @include btn-primary;
}

.btn-secondary {
  @include btn-secondary;
}

.btn-danger {
  @include btn-danger;
}

.btn-success {
  @include btn-success;
}

.btn-warning {
  @include btn-warning;
}

.btn-info {
  @include btn-info;
}

.btn-default {
  @include btn-default;
}

.btn-primary-outline {
  @include btn-primary-outline;
}

.btn-info-outline {
  @include btn-info-outline;
}

.btn-danger-outline {
  @include btn-danger-outline;
}

.btn-info-link {
  @include btn-info-link;
}

.btn-primary-link {
  @include btn-primary-link;
}

.text-lg {
  @include text-400-24-28;
}

.text-sm {
  @include text-400-16-19;
}

.loader {
  width: 100%;
  height: 100%;
  background: var(--bg-primary-blue);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: rgba(6, 24, 44, 0.7);
  border: 1px solid rgba(85, 113, 141, 0.8);
  border-radius: 50px;
}

.mask {
  mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}

.fade {
  mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
}