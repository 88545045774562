@import "src/styles/mixins";

.modal {
  position: absolute;
  background: rgba(12, 46, 78, 0.8);
  border: 1px solid var(--primary);
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(14px);
}

.modal {
  max-width: 1432px;
  max-height: 656px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide-down 300ms ease-out;

  &-lg {
    padding: 40px;
    border-radius: 16px;
  }

  &-sm {
    padding: 30px;
    border-radius: 8px;
  }

  &:focus-visible {
    outline: none;
  }

  &-header {}

  &-body {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1599px) {
  .modal {
   max-width: 1430px;
  }
}

@media screen and (max-width: 1440px) {
  .modal {
    max-width: 1418px;
  }
}

@media screen and (max-width: 1432px) {
  .modal {
    max-width: 1366px;
  }
}

@media screen and (max-width: 1399px) {
  .modal {
    max-width: 1254px;
  }
}
