.threat-active {
  width: 576px;
  height: 48px;
  padding: 8px 12px;
  background: var(--accent-red);
  border-radius: 6px;
  margin-bottom: 28px;

  &__title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--content-white);
    text-align: center;
  }
}

div.header-right+.threat-active {
  margin-bottom: 0;
}

.investigation-active {
  width: 576px;
  height: 48px;
  padding: 8px 12px;
  background: var(--accent-orange);
  border-radius: 6px;
  margin-bottom: 28px;

  &__title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--content-black);
    text-align: center;
  }
}

div.header-right+.investigation-active {
  margin-bottom: 0;
}