@import "src/styles/mixins";

.checkbox {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--content-gray-blue-50);
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;

  &__field {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    background: rgba(147, 165, 182, 0.15);
    border: 1px solid rgba(147, 165, 182, 0.15);
    border-radius: 2px;
    @include flex-align-center;
  }

  & .checkbox__field:checked ~ .checkbox__checkmark {
    background:  var(--accent-turquoise);
  }

  .checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & .checkbox__field:checked ~ .checkbox__checkmark:after {
    display: flex;
  }

  & .checkbox__checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid var(--bg-primary-blue-light);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}