@import "src/styles/mixins";

.dialog {
  padding: 32px;
  width: 544px;
  min-height: 148px;
  backdrop-filter: blur(8px);
  border-radius: 8px;

  &-title, &-content {
    color: var(--bg-primary-blue-light);
  }

  &-title {
    @include text-700-24-24;
    margin-bottom: 12px;
  }

  &-content {
   @include text-400-16-24;
  }

  &-actions {
    @include flex-justify-end;
    margin-top: 52px;
    &:empty {
      margin-top: 0;
    }
  }
}