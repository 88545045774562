@import "src/styles/mixins";

.badge {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: var(--accent-red);

  span {
    display: none;
  }

  &.dot {
    width: 16px;
    height: 16px;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;
    color: var(--content-white);
    @include flex-justify-align-center;

    span {
      display: inline;
    }
  }
}