@import "src/styles/mixins";

.logout {
  width: 373px;

  &-title {
    @include text-700-24-24;
    color: var(--content-gray-blue-50);
  }

  &-actions {
    margin-top: 32px;
    @include flex-justify-between;
  }
}