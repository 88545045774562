@import "src/styles/mixins";

.info-heading {
  @include flex-align-center;
  //margin-top: 28px;

    &__ellipse,
    &__ellipse-outer,
    &__ellipse-inner {
      @include flex-justify-align-center;
      border-radius: 50%;
    }

    &__ellipse {
      width: 39px;
      height: 39px;
      background: rgba(12, 46, 78, 0.5);

      &-outer {
        width: 29px;
        height: 29px;
      }

      &-inner {
        width: 19px;
        height: 19px;
        background: var(--content-white);

        &__text {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

  &__info {
    margin-left: 12px;

    &-text {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }

    &-text.dark {
      color: var(--bg-primary-blue-light);
    }

    &-text.light {
      font-size: 32px;
      line-height: 32px;
      color: var(--content-white);
    }
  }
}